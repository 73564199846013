import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'

import { useLocationsQuery } from 'shared/store/useLocations'
import { LocationType } from 'shared/store/useLocations/types'
import useWholesalers from 'shared/store/useWholesalers'
import { WholesalerType } from 'shared/store/useWholesalers/types'
import { UserRoles } from 'shared/consts'
import useLogin from 'shared/store/useLogin'
import { httpClient } from 'shared/lib'

import { pl, en, de } from './locales'
import Modal from './components/Modal'
import WholesalerCard from './components/WholesalerCard'
import LocationList from './components/LocationsList'
import { WholesalerActionType } from './types'
import NonCoopWholesalerCard from './components/NonCoopWholesalerCard'
import ExternalTools from './components/ExternalTools'
import RejectedWholesalerCard from './components/RejectedWholesalerCard'

const RootStyle = styled(Card)(({ theme }) => ({
   [theme.breakpoints.up('sm')]: {
      display: 'flex',
   },
}))

type ActiveWholeSaler = {
   data: WholesalerType
   type: WholesalerActionType
}

export default function Wholesalers() {
   const { data } = useLocationsQuery()
   const { i18n, t } = useTranslation('WHOLESALERS')
   const [searchParams, setSearchParams] = useSearchParams()
   i18n.addResourceBundle('pl-PL', 'WHOLESALERS', pl)
   i18n.addResourceBundle('en-EN', 'WHOLESALERS', en)
   i18n.addResourceBundle('de-DE', 'WHOLESALERS', de)
   const { user } = useLogin()
   const [activeLocation, setActiveLocation] = useState<LocationType>()
   const [activeWholesaler, setActiveWholesaler] = useState<ActiveWholeSaler>()
   const { data: wholesalers, isFetched } = useWholesalers(activeLocation?.locationSettings.uuid)

   function handleWholesalerChange(wholesalerId: string, type: WholesalerActionType) {
      if (wholesalers) {
         const allWholesalers = wholesalers?.cooperating
            .concat(wholesalers.notCooperating.CLIENT_DATA)
            .concat(wholesalers.notCooperating.CLIENT_ONLY)
            .concat(wholesalers.notCooperating.COOPERATION_EMAIL)
         const newActiveWholesaler = allWholesalers?.find((item) => item.wholesaleUuid === wholesalerId)
         if (newActiveWholesaler) {
            setActiveWholesaler({ type, data: newActiveWholesaler })
         }
      }
   }

   function handleOnChangeLocation(id: string) {
      if (data && id !== activeLocation?.locationSettings.uuid) {
         const newActiveLocation = data.locations.find((item) => item.locationSettings.uuid === id)
         setActiveLocation(newActiveLocation)
         if (newActiveLocation) {
            setSearchParams({ uuid: newActiveLocation.locationSettings.uuid })
         }
      }
   }

   useEffect(() => {
      if (data) {
         if (user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN)) {
            if (data) {
               if (searchParams.get('uuid')) {
                  setActiveLocation(
                     data.locations.find((item) => item.locationSettings.uuid === searchParams.get('uuid'))
                  )
               } else {
                  setActiveLocation(data.locations[0])
               }
            }
         } else {
            const locationWhereAdminIndex = data.locations.findIndex(
               (item) => item.locationSettings.adminUuid === user.uuid
            )
            setActiveLocation(data.locations[locationWhereAdminIndex])
         }
      }
   }, [data, httpClient])

   return (
      <RootStyle>
         <Grid container>
            <LocationList list={data?.locations} active={activeLocation} onChangeLocation={handleOnChangeLocation} />
            <Grid item sm={12} md={10} lg={10}>
               <Box sx={{ p: 1, ml: 1, backgroundColor: 'rgba(255,255,255,0)' }}>
                  {isFetched && wholesalers ? (
                     <>
                        {wholesalers.cooperating.length > 0 && (
                           <>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                 <Typography sx={{ fontWeight: 'bold', ml: 2 }} color="primary" variant="h5">
                                    {t('WHOLESALERS:cooperatingWholesalers')}
                                 </Typography>
                              </Box>
                              <hr style={{ height: 0.5, marginLeft: 20, color: 'gray' }} />
                              <Grid
                                 container
                                 spacing={{ xs: 5, lg: 2 }}
                                 sx={{
                                    paddingTop: 2,
                                    paddingLeft: 2,
                                 }}
                              >
                                 {wholesalers.cooperating.map((wholesaler) =>
                                    wholesaler.cooperationStatus === 'REJECTED' ? (
                                       <RejectedWholesalerCard
                                          key={wholesaler.wholesaleUuid}
                                          handleWholesaler={handleWholesalerChange}
                                          activeWholesaler={wholesaler}
                                       />
                                    ) : (
                                       <WholesalerCard
                                          activeLocationUuid={activeLocation?.locationSettings.uuid}
                                          key={wholesaler.wholesaleUuid}
                                          handleWholesaler={handleWholesalerChange}
                                          activeWholesaler={wholesaler}
                                       />
                                    )
                                 )}
                              </Grid>
                              <hr style={{ height: 0.5, marginLeft: 20, color: 'gray' }} />
                           </>
                        )}

                        {wholesalers.notCooperating?.COOPERATION_EMAIL.length > 0 && (
                           <>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                                 <Typography sx={{ fontWeight: 'bold', ml: 2 }} color="primary" variant="h5">
                                    {t('WHOLESALERS:nonCooperatingWholesalers')}
                                 </Typography>
                              </Box>
                              <Typography sx={{ fontWeight: 'normal', ml: 2, mt: 2 }} color="primary" variant="h6">
                                 {t('WHOLESALERS:oneClickCollaboration')}
                              </Typography>
                              <Grid
                                 container
                                 spacing={{ xs: 5, lg: 2 }}
                                 sx={{
                                    paddingTop: 2,
                                    paddingLeft: 2,
                                 }}
                              >
                                 {wholesalers.notCooperating?.COOPERATION_EMAIL.map((wholesaler) => (
                                    <NonCoopWholesalerCard
                                       activeLocationUuid={activeLocation?.locationSettings.uuid}
                                       key={wholesaler.wholesaleUuid}
                                       handleWholesaler={handleWholesalerChange}
                                       activeWholesaler={wholesaler}
                                    />
                                 ))}
                              </Grid>
                           </>
                        )}
                        <>
                           <Typography sx={{ fontWeight: 'normal', ml: 2, mt: 2 }} color="primary" variant="h6">
                              {t('WHOLESALERS:needDataCollaboration')}
                           </Typography>
                           <Grid
                              container
                              spacing={{ xs: 5, lg: 2 }}
                              sx={{
                                 paddingTop: 2,
                                 paddingLeft: 2,
                              }}
                           >
                              {wholesalers.notCooperating.CLIENT_DATA &&
                                 wholesalers.notCooperating.CLIENT_DATA.map((wholesaler) => (
                                    <NonCoopWholesalerCard
                                       activeLocationUuid={activeLocation?.locationSettings.uuid}
                                       key={wholesaler.wholesaleUuid}
                                       handleWholesaler={handleWholesalerChange}
                                       activeWholesaler={wholesaler}
                                    />
                                 ))}
                              {wholesalers.notCooperating.CLIENT_ONLY &&
                                 wholesalers.notCooperating.CLIENT_ONLY.map((wholesaler) => (
                                    <NonCoopWholesalerCard
                                       activeLocationUuid={activeLocation?.locationSettings.uuid}
                                       key={wholesaler.wholesaleUuid}
                                       handleWholesaler={handleWholesalerChange}
                                       activeWholesaler={wholesaler}
                                    />
                                 ))}
                           </Grid>
                        </>
                        {activeLocation?.locationSettings.uuid === data?.mainLocationUuid && (
                           <>
                              <hr style={{ height: 0.5, marginLeft: 20, color: 'gray' }} />
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                                 <Typography sx={{ fontWeight: 'bold', ml: 2 }} color="primary" variant="h5">
                                    {t('externalTools')}
                                 </Typography>
                              </Box>
                              <Grid
                                 container
                                 spacing={{ xs: 5, lg: 2 }}
                                 sx={{
                                    paddingTop: 2,
                                    paddingLeft: 2,
                                 }}
                              >
                                 <ExternalTools externalTools={wholesalers.externalTools} />
                              </Grid>
                           </>
                        )}
                     </>
                  ) : (
                     <div
                        style={{
                           width: '100%',
                           textAlign: 'center',
                        }}
                     >
                        <CircularProgress color="primary" />
                     </div>
                  )}
               </Box>
            </Grid>
         </Grid>
         {activeWholesaler && (
            <Modal
               open={!!activeWholesaler}
               data={activeWholesaler}
               activeLocationUuid={activeLocation?.locationSettings.uuid}
               handleClose={() => setActiveWholesaler(undefined)}
            />
         )}
      </RootStyle>
   )
}
