/* eslint-disable camelcase */
import { useMutation, useQueryClient } from 'react-query'
import { jwtDecode } from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { LocalStorageKeys, UserRoles } from 'shared/consts'
import { authorizeUser, revokeToken, swapUser } from './services'
import {
   AuthorizationResult,
   JwtTokenData,
   LoggedStateType,
   LoginPayload,
   RepeatLoginPayload,
   StateType,
   SwapLoginPayload,
} from './types'

const INITIAL_STATE: StateType = { loginStatus: 'initial', roles: [], uuid: '', frontendVersionTimestamp: 0 }

function decodeUserToken(token: string) {
   const user = jwtDecode<JwtTokenData>(token)
   const { sub, authorities, expires, frontend_version_timestamp } = user
   const expirationDate = new Date(new Date().getTime() + expires * 1000)
   const roles = authorities.split(',') as UserRoles[]

   return { uuid: sub, expirationDate, roles, frontendVersionTimestamp: frontend_version_timestamp }
}

async function loginIn(payload: LoginPayload): Promise<StateType> {
   const userData = await authorizeUser(payload)
   if (userData.error) {
      throw new Error(userData.error)
   }

   if (!userData.accessToken) {
      return INITIAL_STATE
   }

   const token = userData.accessToken
   const { expirationDate, roles, uuid, frontendVersionTimestamp } = decodeUserToken(token)

   localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token)
   if (userData.refreshToken) {
      localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, userData.refreshToken)
   }
   localStorage.setItem('user_uuid', uuid)
   localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)

   if (Array.isArray(userData.usedUsersSessions)) {
      return {
         loginStatus: 'pending',
         sessions: userData.usedUsersSessions,
         accessToken: userData.accessToken,
         refreshToken: userData.refreshToken,
         frontendVersionTimestamp,
         roles,
         uuid,
      }
   }
   return {
      loginStatus: 'logged',
      frontendVersionTimestamp,
      roles,
      uuid,
   }
}

export async function loginOut(destination: string) {
   const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN) || ''
   const params = new URLSearchParams()
   params.append('refresh_token', refreshToken)
   await revokeToken(params)
   localStorage.removeItem('chooseLocationModal')
   localStorage.removeItem('user_uuid')
   localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN)
   localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN)
   localStorage.removeItem(LocalStorageKeys.EXPIRES_IN)
   return destination
}

async function silentLogin({ accessToken, refreshToken }: RepeatLoginPayload): Promise<LoggedStateType> {
   const { expirationDate, roles, uuid, frontendVersionTimestamp } = decodeUserToken(accessToken)
   localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, accessToken)
   localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, refreshToken)
   localStorage.setItem('user_uuid', uuid)
   localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)

   return {
      loginStatus: 'logged',
      roles,
      uuid,
      frontendVersionTimestamp,
   }
}

async function loginChange(payload: SwapLoginPayload) {
   const userData = await swapUser(payload)

   if (!userData.accessToken || !userData.refreshToken) {
      return INITIAL_STATE
   }

   return silentLogin({ accessToken: userData.accessToken, refreshToken: userData.refreshToken })
}

export function getInitialState(): StateType {
   const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)
   const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN)
   if (token && refreshToken) {
      const { uuid, roles, frontendVersionTimestamp } = decodeUserToken(token)
      return {
         frontendVersionTimestamp,
         loginStatus: 'logged',
         uuid,
         roles,
      }
   }
   return INITIAL_STATE
}

export default function useLogin() {
   const queryClient = useQueryClient()
   const navigate = useNavigate()

   const user = queryClient.getQueryData<StateType>('user') || getInitialState()

   const {
      mutate: login,
      error,
      isError,
      isLoading,
   } = useMutation<StateType, { message: PropType<AuthorizationResult, 'error'> }, LoginPayload>(
      (loginData) => loginIn(loginData),
      {
         onSuccess: (data) => {
            queryClient.setQueryData<StateType>('user', {
               ...user,
               ...data,
            })
            queryClient.invalidateQueries('user')
         },
         // onError: (err) => {
         //    queryClient.setQueryData<StateType>('user', {
         //       ...user,
         //    })
         // },
      }
   )

   const { mutate: reLogin } = useMutation<StateType, unknown, RepeatLoginPayload>((payload) => silentLogin(payload), {
      onSuccess: (data) => {
         queryClient.setQueryData<StateType>('user', {
            ...data,
         })
         queryClient.invalidateQueries('user')
      },
   })

   const { mutate: changeLogin } = useMutation<StateType, unknown, SwapLoginPayload>(
      (payload) => loginChange(payload),
      {
         onSuccess: (data) => {
            queryClient.setQueryData<StateType>('user', {
               ...data,
            })
            queryClient.invalidateQueries('user')
         },
      }
   )

   const { mutate: logout } = useMutation<string, unknown, string>((destination) => loginOut(destination), {
      onSuccess: (destination) => {
         window.localStorage.removeItem('chooseLocationModal')
         queryClient.removeQueries()
         // queryClient.setQueryData<StateType>('user', {
         //    ...INITIAL_STATE,
         // })
         navigate(destination)
      },
   })

   return { user, login, logout, reLogin, changeLogin, error, isError, isLoading }
}
