import CircularProgress from '@mui/material/CircularProgress'
import { Form, FormikProvider, useFormik } from 'formik'
import InputField from 'shared/components/InputField'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

import ChangePasswordModal from 'views/Authorized/components/ChangePasswordModal'
import { useSettingsMutation, useSettingsQuery } from 'shared/store/useSettings'
import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useLocationsQuery } from 'shared/store/useLocations'
import SwitchField from 'shared/components/SwitchField'
import MuiPhone from 'shared/components/PhoneInput'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import { en, pl, de } from './locales'
import schema from './schema'

export default function Settings() {
   const { t, i18n } = useTranslation(['TRANSLATION', 'SETTINGS'])
   const [phoneNumberError, setPhoneNumberError] = useState(false)
   const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false)
   const { user } = useLogin()
   const [phoneNumberLoaded, setPhoneNumberLoaded] = useState(false)
   const [phoneNumber, setPhoneNumber] = useState('')
   const { data: locationsData } = useLocationsQuery()

   i18n.addResourceBundle('pl-PL', 'SETTINGS', pl)
   i18n.addResourceBundle('en-EN', 'SETTINGS', en)
   i18n.addResourceBundle('de-DE', 'SETTINGS', de)

   const { data, isLoading: isSettingsLoading } = useSettingsQuery()
   const queryClient = useQueryClient()
   const { mutate, isLoading } = useSettingsMutation(false)

   const formik = useFormik<SettingsType>({
      initialValues: {
         userSettings: {
            preferences: {
               language: '',
               time: '',
               buyNowOrdersGroup: true,
               priceDifferences: false,
               displayMinPopup: false,
               showProductNamesInCart: false,
               dontShowLocationDialog: false,
            },
            name: '',
            surname: '',
            email: '',
            phoneNumber: '',
         },
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const { values, setValues } = formik

   function handleSubmit() {
      if (data) {
         const newSettings: SettingsType = {
            userSettings: {
               ...values.userSettings,
               phoneNumber,
            },
         }
         mutate(newSettings, {
            onSuccess: () => {
               queryClient.invalidateQueries('userSettings')
            },
         })
      }
   }

   function handlePhoneNumber(value: string, country: string) {
      setPhoneNumber(value)
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }

   useEffect(() => {
      if (data) {
         setValues({
            userSettings: {
               ...data.userSettings,
               preferences: {
                  ...data.userSettings.preferences,
                  showProductNamesInCart: data.userSettings.preferences.showProductNamesInCart === 'true',
                  dontShowLocationDialog: data.userSettings.preferences.dontShowLocationDialog === 'true',
                  buyNowOrdersGroup: data.userSettings.preferences.buyNowOrdersGroup === 'true',
                  priceDifferences: data.userSettings.preferences.priceDifferences === 'true',
                  displayMinPopup: data.userSettings.preferences.displayMinPopup === 'true',
               },
            },
         })
      }
   }, [data])

   useEffect(() => {
      if (data && data.userSettings.phoneNumber) {
         setPhoneNumber(data.userSettings.phoneNumber)
         setPhoneNumberLoaded(true)
      }
   }, [data])

   return (
      <Box>
         <Typography variant="h4" color="primary" sx={{ pb: 3, fontWeight: 'normal' }}>
            {t('SETTINGS:label')}
         </Typography>
         <Card sx={{ backgroundColor: 'rgba(255,255,255,0.7)' }}>
            <Box sx={{ paddingY: 2 }}>
               {!isSettingsLoading && phoneNumberLoaded ? (
                  <div>
                     <FormikProvider value={formik}>
                        <Form>
                           <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, pt: 5 }}>
                              <Typography variant="h5" textAlign="center" color="primary">
                                 {t('SETTINGS:label')}
                              </Typography>
                              <Box sx={{ px: { xl: '25%', lg: '20%', md: '5%', sm: '5%', xs: 2 } }}>
                                 <Stack spacing={{ xs: 2, md: 3 }}>
                                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                                       <InputField
                                          name="userSettings.name"
                                          required
                                          fullWidth
                                          label={t('TRANSLATION:formFields.name')}
                                       />
                                       <InputField
                                          name="userSettings.surname"
                                          required
                                          fullWidth
                                          label={t('TRANSLATION:formFields.surname')}
                                       />
                                       <MuiPhone
                                          variant="outlined"
                                          value={phoneNumber}
                                          onChange={(value, country) => handlePhoneNumber(value, country)}
                                       />
                                    </Stack>
                                    <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
                                       <InputField
                                          name="userSettings.email"
                                          required
                                          fullWidth
                                          sx={{ width: { md: '66%', xs: '100%' } }}
                                          disabled
                                          label={t('TRANSLATION:formFields.email')}
                                       />
                                       <Box
                                          sx={{
                                             display: 'flex',
                                             justifyContent: 'center',
                                             width: { md: '33%', xs: '20%' },
                                          }}
                                       >
                                          <ContainedButton
                                             onClick={() => setChangePasswordModalOpen(true)}
                                             variant="contained"
                                             sx={{ alignSelf: 'center' }}
                                          >
                                             {t('TRANSLATION:changePassword')}
                                          </ContainedButton>
                                       </Box>
                                    </Box>
                                 </Stack>
                              </Box>
                              <Box
                                 sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    gap: 2,
                                    px: { xs: 2 },
                                 }}
                              >
                                 <Box>
                                    <Stack direction="column" alignItems="center" spacing={{ md: 3, xs: 2 }}>
                                       <InputField
                                          name="userSettings.preferences.time"
                                          variant="standard"
                                          sx={{ width: { md: '50%', xs: '100%' } }}
                                          label={t('SETTINGS:settingsSection.logisticMinimumLabel')}
                                          inputProps={{
                                             type: 'time',
                                          }}
                                          InputLabelProps={{
                                             shrink: true,
                                          }}
                                       />
                                       <SwitchField
                                          name="userSettings.preferences.displayMinPopup"
                                          label={t('SETTINGS:settingsSection.logisticMinimumCheckbox')}
                                       />
                                    </Stack>
                                    <Stack
                                       direction={{ md: 'row', xs: 'column' }}
                                       justifyContent="center"
                                       spacing={{ md: 3, xs: 0 }}
                                    >
                                       <SwitchField
                                          name="userSettings.preferences.priceDifferences"
                                          label={t('SETTINGS:settingsSection.showBonuses')}
                                       />
                                       {user.roles.includes(UserRoles.ULTRA) &&
                                          locationsData &&
                                          locationsData.locations.length > 1 && (
                                             <SwitchField
                                                name="userSettings.preferences.dontShowLocationDialog"
                                                label={t('SETTINGS:settingsSection.dontShowLocationDialog')}
                                             />
                                          )}
                                    </Stack>
                                    <Stack
                                       direction={{ md: 'row', xs: 'column' }}
                                       justifyContent="center"
                                       spacing={{ md: 3, xs: 0 }}
                                    >
                                       <SwitchField
                                          name="userSettings.preferences.showProductNamesInCart"
                                          label={t('SETTINGS:settingsSection.showProductNamesInCart')}
                                       />
                                    </Stack>
                                 </Box>
                              </Box>
                           </Box>
                        </Form>
                     </FormikProvider>
                  </div>
               ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                     <CircularProgress color="primary" />
                  </div>
               )}
               <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, width: '100%' }}>
                  <ContainedButton
                     disabled={!formik.isValid || (values.userSettings.phoneNumber && phoneNumberError) || isLoading}
                     onClick={handleSubmit}
                     variant="contained"
                  >
                     {t('TRANSLATION:save')}
                  </ContainedButton>
               </Box>
            </Box>
         </Card>
         <ChangePasswordModal open={changePasswordModalOpen} handleClose={() => setChangePasswordModalOpen(false)} />
      </Box>
   )
}
