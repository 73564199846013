import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { isEqual } from 'lodash'

import { useNotificationContext } from 'shared/context/Notifications'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import {
   addServiceToCart,
   createNewCart,
   fetchCartSummary,
   removeProduct,
   updateCartInfo,
   updatePrices,
   updateProductInfo,
   updateCartServiceInfo,
   deleteCartService,
} from './services'
import {
   FetchCartSummaryResult,
   RemoveProductPayload,
   UpdatePricesResult,
   UpdateProductType,
   UpdateCartInfoPayload,
   EditServicePayload,
} from './types'

export function useCartSummaryQuery(): UseQueryResult<FetchCartSummaryResult> {
   return useQuery('mainCart', fetchCartSummary, { refetchOnWindowFocus: 'always' })
}

export function useRemoveProductMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, RemoveProductPayload, unknown>(
      (payload) => removeProduct(payload),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('mainCart')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}

export function useUpdatePricesMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<UpdatePricesResult, AxiosError<ErrorType> | Error, string, unknown>(
      (cartUuid) => updatePrices(cartUuid),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               invalidateCarts(queryClient)
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}

export function useNewCartMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, unknown, unknown>(createNewCart, {
      onSuccess: (data) => {
         if (data.status === 200) {
            invalidateCarts(queryClient)
            addNotification('successCartCreate', 'success')
         }
      },
      onError: (error) => {
         if (isAxiosError<ErrorType>(error)) {
            addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
         } else {
            addNotification('failedSave', 'error')
         }
      },
   })
}

export function useUpdateProductMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, UpdateProductType, unknown>(
      (payload) => updateProductInfo(payload),
      {
         onSuccess: () => {
            addNotification('successSave', 'success')
         },
         onError: (error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         },
         onSettled: () => queryClient.invalidateQueries('mainCart'),
      }
   )
}

export function useUpdateCartInfoMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, UpdateCartInfoPayload, unknown>(
      (payload) => {
         const initialData = queryClient.getQueryData<FetchCartSummaryResult>('mainCart')
         const initialPayload = initialData
            ? {
                 cartName: initialData.cartDetailsDTO.cartName,
                 cartDescription: initialData.cartDetailsDTO.cartDescription,
              }
            : {}
         if (
            isEqual(initialPayload.cartName, payload.payload.cartName) &&
            isEqual(initialPayload.cartDescription, payload.payload.cartDescription)
         ) {
            throw new Error('apiErrors.nothingChanged')
         } else {
            return updateCartInfo(payload)
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
            queryClient.invalidateQueries('mainCart')
         },
      }
   )
}

export function useAddServiceMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, { cartUuid: string; serviceUuid: string }, unknown>(
      ({ cartUuid, serviceUuid }) => addServiceToCart(cartUuid, serviceUuid),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('mainCart')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         },
      }
   )
}

export function useUpdateServiceMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<
      Result,
      AxiosError<ErrorType> | Error,
      { payload: EditServicePayload; cartUuid: string; serviceUuid: string },
      unknown
   >(({ payload, cartUuid, serviceUuid }) => updateCartServiceInfo(payload, cartUuid, serviceUuid), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('mainCart')
            addNotification('successSave', 'success')
         }
      },
      onError: (error) => {
         if (isAxiosError<ErrorType>(error)) {
            addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
         } else {
            addNotification('failedSave', 'error')
         }
      },
   })
}

export function useDeleteServiceMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, { cartUuid: string; serviceUuid: string }, unknown>(
      ({ cartUuid, serviceUuid }) => deleteCartService(cartUuid, serviceUuid),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('mainCart')
               addNotification('successSave', 'success')
            }
         },
         onError: (error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSave', 'error')
            }
         },
      }
   )
}
