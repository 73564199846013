import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import {
   AddWholesalerPayload,
   AskCooperationPayload,
   DeleteWholesalerPayload,
   EditDeliveryTimesPayload,
   FetchDeliveryTimesResult,
   WholesalerDeliveryTimes,
   WholesalerType,
} from './types'

export function fetchWholesalers(locationId?: string) {
   let errMessage = ''

   return httpClient
      .get<WholesalerType[]>(`location/${locationId}/cooperation`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage =
               `status: ${error.response?.status}, message:${error.response?.data?.errors[0].defaultMessage}` ||
               'Unexpected error'
         } else {
            errMessage = error.message
         }
         consoleMessage('WHOLESALERS fetchWholesalers service', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function fetchWholesalerDeliveryTimes(
   cooperationUuid: string,
   locationUuid: string
): Promise<WholesalerDeliveryTimes> {
   let errMessage = ''

   return httpClient
      .get<FetchDeliveryTimesResult>(`location/${locationUuid}/cooperation/${cooperationUuid}/delivery-times`)
      .then((res) => {
         if (res.data) {
            return {
               deliveryTimes: res.data.deliveryTimes,
               uuid: res.data.uuid,
            }
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('WHOLESALERS fetchWholesalerDeliveryTimes service', errMessage, 'error')
         throw error
      })
}

export function editWholesalerDeliveryTimes(payload: EditDeliveryTimesPayload) {
   let errMessage = ''

   return httpClient
      .put<Result>(`location/${payload.locationUuid}/cooperation/${payload.cooperationUuid}/delivery-times`, {
         deliveryTimes: payload.deliveryTimes,
      })
      .then((res) => {
         return {
            status: res.status,
         }
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('WHOLESALERS editWholesalerDeliveryTimes service', errMessage, 'error')
         throw error
      })
}

export function deleteWholesaler(payload: DeleteWholesalerPayload) {
   let errMessage = ''

   return httpClient
      .delete(`location/${payload.activeLocationUuid}/cooperation/${payload.cooperationUuid}`)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('WHOLESALERS deleteWholesaler service', errMessage, 'error')
         throw error
      })
}

export function resendWholesalerCoopMail(locationUuid: string, cooperationUuid: string) {
   let errMessage = ''

   return httpClient
      .post(`location/${locationUuid}/cooperation/${cooperationUuid}/resend`)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('WHOLESALERS resendWholesalerCoopMail service', errMessage, 'error')
         throw error
      })
}

export function addWholesaler(locationUuid: string, payload: AddWholesalerPayload) {
   let errMessage = ''

   return httpClient
      .post(`location/${locationUuid}/cooperation`, payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('WHOLESALERS addWholesaler service', errMessage, 'error')
         throw error
      })
}

export function editWholesaler(locationUuid: string, cooperationUuid: string, payload: AddWholesalerPayload) {
   let errMessage = ''

   return httpClient
      .put(`location/${locationUuid}/cooperation/${cooperationUuid}`, payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('WHOLESALERS editWholesaler service', errMessage, 'error')
         throw error
      })
}

export function askForCooperation(locationUuid: string, payload: AskCooperationPayload) {
   let errMessage = ''
   return httpClient
      .post(`location/${locationUuid}/cooperation/establish`, payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('WHOLESALERS askForCooperation service', errMessage, 'error')
         throw error
      })
}
