import { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import {
   CreateConfigResult,
   GetTokenResult,
   ModifyPayload,
   StatusesResult,
   ExternalStatusType,
   GetExternalFieldsType,
} from '../types'

export function getBaselinkerTokenService() {
   let errMessage = ''

   return httpClient
      .get<GetTokenResult>('baselinker/')
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('BASELINKER getBaselinkerTokn service', errMessage, 'error')
         throw error
      })
}

export function getExternalInformationsService() {
   let errMessage = ''
   return httpClient
      .get<GetExternalFieldsType>('baselinker/extra-fields')
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('BASELINKER getExternalInformations service', errMessage, 'error')
         throw error
      })
}

export function createBaselinkerConfigService(token: string, locationUuid: string) {
   let errMessage = ''
   return httpClient
      .post<CreateConfigResult>('baselinker/', { token, locationUuid })
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('BASELINKER createBaselinkerConfig service', errMessage, 'error')
         throw error
      })
}

export function getBaselinkerConfigService(configUuid: string) {
   let errMessage = ''
   return httpClient
      .get<GetTokenResult>(`baselinker/${configUuid}`)
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('BASELINKER getBaselinkerConfig service', errMessage, 'error')
         throw error
      })
}

export function getBaselinkerStatusesService() {
   let errMessage = ''
   return httpClient
      .get<ExternalStatusType>('baselinker/order-statuses')
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('BASELINKER getBaselinkerStatuses service', errMessage, 'error')
         throw error
      })
}

export function editBaselinkerConfigService(configUuid: string, payload: ModifyPayload) {
   let errMessage = ''
   return httpClient
      .patch<StatusesResult>(`baselinker/${configUuid}`, payload)
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('BASELINKER editBaselinkerConfig service', errMessage, 'error')
         throw error
      })
}

export function deleteBaselinkerConfigService(configUuid: string) {
   let errMessage = ''
   return httpClient
      .delete<GetTokenResult>(`baselinker/${configUuid}`)
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('BASELINKER deleteBaselinkerTokn service', errMessage, 'error')
         throw error
      })
}
