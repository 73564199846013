import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import {
   FetchCartSummaryResult,
   UpdateProductType,
   RemoveProductPayload,
   UpdateCartInfoPayload,
   EditServicePayload,
} from './types'

export function fetchCartSummary() {
   let errMessage = ''

   return httpClient
      .get<FetchCartSummaryResult>('/carts/default-summary')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage =
               `status: ${error.response?.status}, message:${error.response?.data?.errors[0].defaultMessage}` ||
               'Unexpected error'
         } else {
            errMessage = error.message
         }
         consoleMessage('MAIN CART fetchCartSummary()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function removeProduct(payload: RemoveProductPayload) {
   const url = payload.removeFromUnavailable
      ? `/carts/${payload.cartUuid}/products/${payload.uuid}/?available=false`
      : `/carts/${payload.cartUuid}/products/${payload.uuid}`

   let errMessage = ''

   return httpClient
      .delete<Result>(url)
      .then((res) => ({ status: res.status }))
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].code || 'Something went wrong in removeProduct()'
         } else {
            errMessage = error.message
         }
         consoleMessage('MAIN CART removeProduct()', errMessage, 'error')
         throw error
      })
}

export function updateProductInfo(updateProductPayload: UpdateProductType) {
   let errMessage = ''

   return httpClient
      .patch<Result>(
         `/carts/${updateProductPayload.cartUuid}/products/${updateProductPayload.cartProductUuid}`,
         updateProductPayload.payload
      )
      .then((res) => ({ status: res.status }))
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].code || 'Something went wrong in updateProductInfo()'
         } else {
            errMessage = error.message
         }
         consoleMessage('MAIN CART updateProductInfo()', errMessage, 'error')
         throw error
      })
}

export function updatePrices(cartUuid: string) {
   let errMessage = ''

   return httpClient
      .get(`/carts/${cartUuid}/products?actual=true`)
      .then((res) => ({ status: res.status, products: res.data }))
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].code || 'Something went wrong in updatePrices()'
         } else {
            errMessage = error.message
         }
         consoleMessage('MAIN CART updatePrices()', errMessage, 'error')
         throw error
      })
}

export function createNewCart() {
   let errMessage = ''

   return httpClient
      .post<Result>('/carts/')
      .then((res) => ({ status: res.status }))
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].code || 'Something went wrong in createNewCart()'
         } else {
            errMessage = error.message
         }
         consoleMessage('MAIN CART createNewCart()', errMessage, 'error')
         throw error
      })
}

export function updateCartInfo(payload: UpdateCartInfoPayload) {
   let errMessage = ''

   return httpClient
      .patch<Result>(`/carts/${payload.cartUuid}`, payload.payload)
      .then((res) => ({ status: res.status }))
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].code || 'Something went wrong in createNewCart()'
         } else {
            errMessage = error.message
         }
         consoleMessage('MAIN CART createNewCart()', errMessage, 'error')
         throw error
      })
}

export function addServiceToCart(cartUuid: string, serviceUuid: string) {
   let errMessage = ''

   return httpClient
      .post<Result>(`/carts/${cartUuid}/additional-services/${serviceUuid}`)
      .then((res) => ({ status: res.status }))
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].code || 'Something went wrong in createNewCart()'
         } else {
            errMessage = error.message
         }
         consoleMessage('MAIN CART createNewCart()', errMessage, 'error')
         throw error
      })
}

export function updateCartServiceInfo(payload: EditServicePayload, cartUuid: string, serviceUuid: string) {
   let errMessage = ''

   return httpClient
      .patch<Result>(`/carts/${cartUuid}/additional-services/${serviceUuid}`, payload)
      .then((res) => ({ status: res.status }))
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].code || 'Something went wrong in createNewCart()'
         } else {
            errMessage = error.message
         }
         consoleMessage('MAIN CART createNewCart()', errMessage, 'error')
         throw error
      })
}

export function deleteCartService(cartUuid: string, serviceUuid: string) {
   let errMessage = ''

   return httpClient
      .delete<Result>(`/carts/${cartUuid}/additional-services/${serviceUuid}`)
      .then((res) => ({ status: res.status }))
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].code || 'Something went wrong in createNewCart()'
         } else {
            errMessage = error.message
         }
         consoleMessage('MAIN CART createNewCart()', errMessage, 'error')
         throw error
      })
}
