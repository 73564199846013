import { Yup } from 'shared/lib'

export const schema = Yup.object().shape({
   depositIndex: Yup.string().nullable(),
   depositPrice: Yup.string().nullable(),
   wholesalerIndex: Yup.string().required(),
   times: Yup.number().required().moreThan(0),
   catalogPrice: Yup.number().nullable(),
   taxRate: Yup.number().moreThan(0),
   quantity: Yup.number().nullable(),
})
