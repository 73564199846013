import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { StateType } from './types'

export function fetchSavedCarts(filter?: string): Promise<StateType> {
   let errMessage = ''
   return httpClient
      .get(`carts/?dateFilter=${filter || 'LAST_90_DAYS'}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage =
               `status: ${error.response?.status}, message:${error.response?.data?.errors[0].defaultMessage}` ||
               'Unexpected error'
         } else {
            errMessage = error.message
         }
         consoleMessage('SAVED CARTS fetchSavedCarts service', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function deleteCart(uuid: string) {
   let errMessage = ''
   return httpClient
      .delete<Result>(`carts/${uuid}`)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SAVED CARTS deleteCart service', errMessage, 'error')
         throw error
      })
}

export function setActiveCart(uuid: string) {
   let errMessage = ''

   return httpClient
      .put<Result>(`carts/${uuid}/active`)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('SAVED CARTS setActiveCart service', errMessage, 'error')
         throw error
      })
}
