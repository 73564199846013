import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables'
import React, { useCallback, useState, useEffect } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import CircularProgress from '@mui/material/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import IconButton from '@mui/material/IconButton'
import styled from '@mui/material/styles/styled'
import TextField from '@mui/material/TextField'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import { CSVLink } from 'react-csv'

import { useChangeOrderNameMutation, useOrdersHistory, useSearchOrdersHistory } from 'shared/store/useOrdersHistory'
import reverseDateRepresentation from 'shared/utils/helpers/reverseDateRepresentation'
import { useSettingsMutation, useSettingsQuery } from 'shared/store/useSettings'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { sortByDate, sortNumber } from 'shared/utils/helpers/sorters'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useLocationsQuery } from 'shared/store/useLocations'
import { Order } from 'shared/store/useOrdersHistory/types'
import TableHeadCell from 'shared/components/TableHeadCell'
import { useProfileQuery } from 'shared/store/useProfile'

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { ChangeNameDetailsType, PrintType, StoredOrderDetails } from './types'
import { StyledClientPrintIcon, StyledFullPrintIcon } from './styles'
import OrderDetails from './components/OrderDetails'
import OrderPrint from './components/OrderPrint'
import { pl, en, de } from './locales'

function OrdersHistory() {
   const { i18n, t } = useTranslation(['TRANSLATION', 'ORDERS_HISTORY'])
   i18n.addResourceBundle('pl-PL', 'ORDERS_HISTORY', pl)
   i18n.addResourceBundle('en-EN', 'ORDERS_HISTORY', en)
   i18n.addResourceBundle('de-DE', 'ORDERS_HISTORY', de)
   const [searchPhrase, setSearchPhrase] = useState('')
   const [selectedFilter, setSelectedFilter] = useState<string>('LAST_90_DAYS')
   const [filterByStatus, setFilterByStatus] = useState<PropType<OrderType, 'status'>[]>([])
   const [openDetails, handleOpenDetails] = useState<string[]>()
   const [printDetails, handleOpenPrint] = useState<string[]>()
   const [printType, setPrintType] = useState<PrintType>('clientPrint')
   const [savedOrderDetails, handleOrdersDetails] = useState<StoredOrderDetails[]>([])
   const { data, isLoading, isError, error } = useOrdersHistory()
   const [searchParams] = useSearchParams()
   const { data: settingsData } = useSettingsQuery()
   const { mutate: mutateSettings, isLoading: isSettingsMutateLoading } = useSettingsMutation(true)
   const { data: profileSettings } = useProfileQuery()
   const { data: locationsData } = useLocationsQuery()
   const location = useLocation()
   const navigate = useNavigate()
   const [changeNameDetails, setChangeNameDetails] = useState<ChangeNameDetailsType>({
      orderName: '',
      orderUuid: '',
   })
   const { mutate, isLoading: isMutateLoading } = useSearchOrdersHistory()
   const { mutate: changeOrderNameMutate, isLoading: changeOrderNameMutateIsLoading } = useChangeOrderNameMutation()

   const activeLocationSettings = locationsData?.locations.find(
      (item) => item.locationSettings.uuid === profileSettings?.locationUuid
   )

   function handleOnSearch(filter?: string, resetFilters?: boolean) {
      if (filter) {
         setSelectedFilter(filter)
      }
      if (!isLoading) {
         if (resetFilters) {
            mutate({
               searchValue: '',
               filter: 'LAST_90_DAYS',
            })
         } else if (filter || selectedFilter) {
            mutate({
               searchValue: searchPhrase,
               filter: filter ?? selectedFilter,
               filterStatus: filterByStatus.length > 0 ? filterByStatus : undefined,
            })
         } else {
            mutate({ searchValue: searchPhrase, filterStatus: filterByStatus.length > 0 ? filterByStatus : undefined })
         }
      }
   }

   function updateDetailsStore(newRecord: StoredOrderDetails) {
      handleOrdersDetails((prevState) => [...prevState, newRecord])
   }

   function handleGroupingChange() {
      if (settingsData) {
         const newBuyNowOrdersGroup = settingsData.userSettings.preferences.buyNowOrdersGroup !== 'true'
         mutateSettings(
            {
               ...settingsData,
               userSettings: {
                  ...settingsData.userSettings,
                  preferences: {
                     ...settingsData.userSettings.preferences,
                     showProductNamesInCart: settingsData.userSettings.preferences.showProductNamesInCart === 'true',
                     buyNowOrdersGroup: newBuyNowOrdersGroup,
                     priceDifferences: settingsData.userSettings.preferences.priceDifferences === 'true',
                     displayMinPopup: settingsData.userSettings.preferences.displayMinPopup === 'true',
                     dontShowLocationDialog: settingsData.userSettings.preferences.dontShowLocationDialog === 'true',
                  },
               },
            },
            {
               onSuccess: () => {
                  handleOrdersDetails(
                     savedOrderDetails.filter(
                        (item) => item.orderType !== 'BUY_NOW' && item.orderType !== 'GROUPED_BUY_NOW'
                     )
                  )
                  handleOnSearch()
               },
            }
         )
      }
   }

   useEffect(() => {
      if (isError) {
         navigate('/dashboard/error-page', {
            state: {
               variant: 'backend',
               path: location.pathname,
               error: {
                  message: error,
                  stack: 'unknown',
               },
            },
         })
      }
   }, [isError, isLoading])

   const options: MUIDataTableOptions = {
      responsive: 'simple',
      selectableRows: 'none',
      rowsPerPageOptions: [10, 25, 50],
      rowsPerPage: Number(window.localStorage.getItem('itemsPerPage')) || 25,
      onChangeRowsPerPage: (numberOfRows: number) =>
         window.localStorage.setItem('itemsPerPage', numberOfRows.toString()),
      print: false,
      download: false,
      filter: false,
      viewColumns: false,
      textLabels: {
         body: {
            noMatch: t('ORDERS_HISTORY:noResults'),
            columnHeaderTooltip: (column) => `${t('ORDERS_HISTORY:sortBy')} ${column.label?.toLowerCase()}`,
         },
         pagination: {
            rowsPerPage: t('ORDERS_HISTORY:rowsPerPage'),
            displayRows: t('ORDERS_HISTORY:of'),
         },
      },
      customSearchRender() {
         return (
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: { md: 'row', sm: 'column', xs: 'column' },
                  alignItems: { md: 'center', sm: 'flex-start', xs: 'flex-start' },
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  gap: 1,
                  mt: 1,
                  width: '100%',
               }}
            >
               <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <TextField
                     onKeyDown={(e) => {
                        if (e.key === 'Enter') handleOnSearch()
                     }}
                     label={t('TRANSLATION:searchAndFilters.searchPhrase')}
                     variant="standard"
                     onChange={(e) => setSearchPhrase(e.target.value)}
                     value={searchPhrase}
                  />
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={selectedFilter === 'ALL'}
                           onChange={() => {
                              handleOnSearch('ALL')
                           }}
                        />
                     }
                     label={translation}
                  />
               </Box>
               <OutlinedButton variant="outlined" onClick={() => handleOnSearch()}>
                  {t('TRANSLATION:search')} <Box component={SearchIcon} sx={{ width: 20, height: 20 }} />
               </OutlinedButton>
               <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  <ContainedButton
                     variant="contained"
                     onClick={() => {
                        setSearchPhrase('')
                        setFilterByStatus([])
                        handleOnSearch('LAST_90_DAYS', true)
                     }}
                  >
                     {t('TRANSLATION:searchAndFilters.clearFilters')}
                  </ContainedButton>
                  {data?.availableFilters
                     .filter((item) => item !== 'ALL')
                     .reverse()
                     .map((item) => (
                        <ContainedButton
                           onClick={() => {
                              handleOnSearch(item)
                           }}
                           disabled={selectedFilter === item || isMutateLoading}
                           variant="contained"
                           key={item}
                        >
                           {t(`TRANSLATION:searchAndFilters.filters.${item}`)}
                        </ContainedButton>
                     ))}
               </Box>
               <FormControl sx={{ minWidth: 200, alignSelf: 'center' }} component="fieldset" variant="outlined">
                  <InputLabel>{t('ORDERS_HISTORY:orderStatusFilter')}</InputLabel>
                  <Select
                     sx={{ minWidth: 200 }}
                     multiple
                     onClose={() => handleOnSearch()}
                     value={filterByStatus}
                     onChange={(e) => setFilterByStatus(e.target.value as unknown as PropType<OrderType, 'status'>[])}
                     label={t('ORDERS_HISTORY:orderStatusFilter')}
                  >
                     <MenuItem value="SUCCESSFUL">{t('ORDERS_HISTORY:successStatus')}</MenuItem>
                     <MenuItem value="PARTIALLY_SUCCESSFUL">{t('ORDERS_HISTORY:partiallySuccess')}</MenuItem>
                     <MenuItem value="FAILED">{t('ORDERS_HISTORY:failedStatus')}</MenuItem>
                  </Select>
               </FormControl>
               <FormControlLabel
                  sx={{ alignSelf: 'center', justifySelf: 'flex-end' }}
                  control={
                     <Switch
                        checked={settingsData?.userSettings.preferences.buyNowOrdersGroup === 'true'}
                        onChange={handleGroupingChange}
                        disabled={isSettingsMutateLoading}
                     />
                  }
                  label={t('ORDERS_HISTORY:groupBuyNows')}
               />
            </Box>
         )
      },
      search: true,
      searchAlwaysOpen: true,
      fixedHeader: false,
      setRowProps: (row, _, rowIndex) => ({
         style: {
            backgroundColor: getHighlightColor(data?.orders[rowIndex].status ?? 'SUCCESSFUL'),
            textAlign: 'center',
            cursor: 'pointer',
         },
      }),
      onRowClick: (rowData, rowMeta) => {
         const tempData = data?.orders[rowMeta.dataIndex]
         if (tempData) {
            handleOpenDetails(tempData.orderUuids)
         }
      },
   }

   function getHighlightColor(orderStatus: PropType<OrderType, 'status'>) {
      switch (orderStatus) {
         case 'FAILED':
            return 'rgba(255,0,0,0.5)'
         case 'PARTIALLY_SUCCESSFUL':
            return 'rgb(255, 136, 0,0.7)'
         case 'SUCCESSFUL':
         default:
            return 'transparent'
      }
   }

   function handlePrint(id: number, event: React.MouseEvent<SVGSVGElement, MouseEvent>, typeOfPrint: PrintType) {
      event.preventDefault()
      event.stopPropagation()
      handleOpenPrint(data?.orders[id].orderUuids)
      setPrintType(typeOfPrint)
   }

   function renderCartName(orderType?: PropType<Order, 'orderType'>, cartName?: string, orderDate?: string) {
      const reg = /^(?:0?[1-9]|[12][0-9]|3[01])\/(?:0?[1-9]|1[012])\/\d{4}$/
      if (cartName) {
         if (orderType === 'BUY_NOW' && reg.test(cartName)) {
            return i18n.t('ORDERS_HISTORY:buyNowCartName', { date: orderDate })
         }
      }
      return cartName
   }

   function handleChangeOrderName() {
      if (changeNameDetails.orderUuid) {
         changeOrderNameMutate(
            { orderName: changeNameDetails.orderName, orderUuid: changeNameDetails.orderUuid },
            {
               onSuccess: () => {
                  setChangeNameDetails({ orderUuid: '', orderName: '' })
               },
            }
         )
      }
   }

   const columns: MUIDataTableColumn[] = [
      {
         name: 'orderUuids',
         options: {
            filter: false,
            searchable: false,
            display: 'excluded',
         },
      },
      {
         name: 'orderDate',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortCompare: (order) => (obj1: { data: string }, obj2: { data: string }) =>
               order === 'asc'
                  ? sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data))
                  : -sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data)),
            setCellProps: () => ({
               style: { width: 150 },
            }),
         },
      },
      {
         name: 'cartName',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customBodyRenderLite(rowIndex) {
               const temp = data?.orders.at(rowIndex)
               if (temp?.orderUuids[0] === changeNameDetails.orderUuid) {
                  return (
                     <Box
                        onKeyDown={(e) => {
                           if (e.key === 'Escape') {
                              setChangeNameDetails({
                                 orderName: '',
                                 orderUuid: '',
                              })
                           }
                           if (e.key === 'Enter') {
                              handleChangeOrderName()
                           }
                        }}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}
                     >
                        <TextField
                           label={t('TRANSLATION:columnNames.cartName')}
                           defaultValue={changeNameDetails.orderName}
                           disabled={changeOrderNameMutateIsLoading}
                           inputProps={{
                              maxLength: 50,
                           }}
                           multiline
                           onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                           }}
                           onChange={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setChangeNameDetails({ ...changeNameDetails, orderName: e.target.value })
                           }}
                        />
                        <IconButton
                           disabled={changeOrderNameMutateIsLoading}
                           onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              if (temp?.cartName !== changeNameDetails.orderName) {
                                 handleChangeOrderName()
                              } else {
                                 setChangeNameDetails({
                                    orderUuid: '',
                                    orderName: '',
                                 })
                              }
                           }}
                           sx={{ '&:hover': { color: 'green', fontWeight: 'bold' } }}
                        >
                           <CheckIcon />
                        </IconButton>
                     </Box>
                  )
               }
               return (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                     <span>{renderCartName(temp?.orderType, temp?.cartName, temp?.orderDate)}</span>
                     {temp?.orderType !== 'GROUPED_BUY_NOW' && (
                        <IconButton
                           onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setChangeNameDetails({
                                 orderUuid: temp?.orderUuids[0] || '',
                                 orderName: temp?.cartName || '',
                              })
                           }}
                        >
                           <EditIcon />
                        </IconButton>
                     )}
                  </Box>
               )
            },
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            setCellProps: () => ({
               style: { width: 300 },
            }),
         },
      },
      {
         name: 'orderType',
         options: {
            sort: false,
            filter: false,
            customBodyRenderLite(rowIndex) {
               const temp = data?.orders.at(rowIndex)
               return temp?.orderType ? t(`ORDERS_HISTORY:orderType.${temp.orderType}`) : ''
            },
         },
      },
      {
         name: 'cartDescription',
         options: {
            sort: false,
            filter: false,
            setCellProps: () => ({
               style: { width: 350 },
            }),
         },
      },
      {
         name: 'phoneNumber',
         options: {
            sort: false,
            filter: false,
         },
      },
      {
         name: 'formattedValue',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadLabelRender: (value) => returnPriceTypeHeader(value.name),
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
                  shouldShowPriceType: true,
               }),
            sortCompare: (order) => {
               return (obj1: any, obj2: any) => {
                  if (typeof obj1.rowData[5] === 'number' && typeof obj2.rowData[5] === 'number') {
                     return order === 'asc'
                        ? sortNumber(obj1.rowData[5], obj2.rowData[5])
                        : -sortNumber(obj1.rowData[5], obj2.rowData[5])
                  }
                  if (!obj1.rowData[5] && obj2.rowData[5]) {
                     return 1
                  }
                  if (obj1.rowData[5] && !obj2.rowData[5]) {
                     return -1
                  }
                  return 1
               }
            },
         },
      },
      {
         name: 'value',
         options: {
            display: 'excluded',
         },
      },
      {
         name: 'formattedClientValueWithServices',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadLabelRender: (value) => returnPriceTypeHeader(value.name),
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
                  shouldShowPriceType: true,
               }),
            sortCompare: (order) => {
               return (obj1: any, obj2: any) => {
                  if (typeof obj1.rowData[8] === 'number' && typeof obj2.rowData[8] === 'number') {
                     return order === 'asc'
                        ? sortNumber(obj1.rowData[8], obj2.rowData[8])
                        : -sortNumber(obj1.rowData[8], obj2.rowData[8])
                  }
                  if (!obj1.rowData[8] && obj2.rowData[8]) {
                     return 1
                  }
                  if (obj1.rowData[8] && !obj2.rowData[8]) {
                     return -1
                  }
                  return 1
               }
            },
         },
      },
      {
         name: 'clientValueWithServices',
         options: {
            display: 'excluded',
         },
      },
      {
         name: 'paymentLink',
         options: {
            display: 'excluded',
         },
      },
      {
         name: 'userName',
         options: {
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortThirdClickReset: true,
            filter: false,
         },
      },
      {
         name: '',
         options: {
            sort: false,
            filter: false,
            customHeadLabelRender: () => '',
            // eslint-disable-next-line react/no-unstable-nested-components
            customBodyRenderLite: (RowIndex) => (
               <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
                  {data?.orders[RowIndex].orderType !== 'BASELINKER' && (
                     <Tooltip title={t('ORDERS_HISTORY:clientPrint') || ''}>
                        <StyledClientPrintIcon
                           onClick={(e) => {
                              handlePrint(RowIndex, e, 'clientPrint')
                           }}
                        />
                     </Tooltip>
                  )}
                  <Tooltip title={t('ORDERS_HISTORY:fullPrint') || ''}>
                     <StyledFullPrintIcon
                        onClick={(e) => {
                           handlePrint(RowIndex, e, 'fullPrint')
                        }}
                     />
                  </Tooltip>
               </Box>
            ),
         },
      },
   ]

   function returnPriceTypeHeader(name: string) {
      return (
         <span>
            {t(`TRANSLATION:columnNames.${name}`)} {t(`TRANSLATION:${profileSettings?.priceType}`)}
         </span>
      )
   }

   function generateCSV() {
      const CSVData = []
      if (data) {
         const title: string[] = []
         columns
            .filter((item) => {
               if (item.options) {
                  if (item.options.display === 'excluded' || item.name === '') return null
               }
               return item
            })
            .map((item) => title.push(t(`TRANSLATION:columnNames.${item.name}`)))
         CSVData.push(title)
         data.orders.map((item) => {
            return CSVData.push([
               item.orderDate,
               item.cartName,
               item.cartDescription,
               item.formattedValue,
               item.formattedClientValue,
               item.phoneNumber !== null ? item.phoneNumber : '',
               item.userName,
            ])
         })
      }

      const date = new Date()

      return (
         <CSVLink
            filename={t('ORDERS_HISTORY:csvFileName', { date: date.toLocaleString(i18n.language) })}
            data={CSVData}
         >
            <ContainedButton variant="contained">{t('ORDERS_HISTORY:generateCSV')}</ContainedButton>
         </CSVLink>
      )
   }

   const translation = t('TRANSLATION:searchAndFilters.searchEverywhere') || ''

   const getTitle = useCallback(() => {
      const orderUuids = openDetails || printDetails
      const target = data?.orders.find((item) => item.orderUuids === orderUuids)
      if (target) {
         if (target.orderType === 'GROUPED_BUY_NOW') {
            return t('ORDERS_HISTORY:buyNowOrderPrintTitle', {
               orderDate: target?.orderDate,
            })
         }
         return t('ORDERS_HISTORY:orderPrintTitle', {
            orderName: target?.cartName,
            orderDate: target?.orderDate,
            userName: target.userName,
         })
      }
      return ''
   }, [openDetails, printDetails])

   useEffect(() => {
      const uuid = searchParams.get('uuid')
      if (data) {
         if (uuid) {
            const tempData = data.orders.find((item) => item.orderUuids.includes(uuid))
            if (tempData) {
               handleOpenDetails(tempData.orderUuids)
            }
         }
      }
   }, [searchParams.get('uuid'), data])

   return (
      <Box>
         {!isLoading ? (
            <>
               <div style={{ width: '100%' }}>
                  {data && Array.isArray(data.orders) && (
                     <StyledTableWrapper>
                        <MUIDataTable
                           title=""
                           data={data.orders}
                           columns={columns.map((el) => ({
                              ...el,
                              key: el,
                              label: t(`TRANSLATION:columnNames.${el.name}`),
                           }))}
                           options={options}
                        />
                     </StyledTableWrapper>
                  )}
               </div>
               {data && data.orders.length > 0 && (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, width: '100%' }}>
                     {generateCSV()}
                  </Box>
               )}
               {!!openDetails && (
                  <OrderDetails
                     orderUuids={openDetails}
                     open={!!openDetails}
                     title={getTitle()}
                     onClose={() => handleOpenDetails(undefined)}
                     priceType={activeLocationSettings?.markupSettings.priceType}
                     details={savedOrderDetails}
                     formattedGrossValue={
                        data?.orders.find((item) => item.orderUuids === openDetails)?.formattedGrossValue
                     }
                     formattedNetValue={data?.orders.find((item) => item.orderUuids === openDetails)?.formattedNetValue}
                     formattedServicesValue={
                        data?.orders.find((item) => item.orderUuids === openDetails)?.formattedAdditionalServicesValue
                     }
                     netDeliveryCost={data?.orders.find((item) => item.orderUuids === openDetails)?.netDeliveryCost}
                     grossDeliveryCost={data?.orders.find((item) => item.orderUuids === openDetails)?.grossDeliveryCost}
                     updateDetailsStore={updateDetailsStore}
                     orderType={data?.orders.find((item) => item.orderUuids === openDetails)?.orderType}
                  />
               )}
               {!!printDetails && (
                  <OrderPrint
                     orderUuids={printDetails}
                     open={!!printDetails}
                     cartDescription={
                        data?.orders.find((product) => product.orderUuids === printDetails)?.cartDescription
                     }
                     printType={printType}
                     netDeliveryCost={data?.orders.find((item) => item.orderUuids === printDetails)?.netDeliveryCost}
                     grossDeliveryCost={
                        data?.orders.find((item) => item.orderUuids === printDetails)?.grossDeliveryCost
                     }
                     formattedAdditionalServicesValue={
                        data?.orders.find((item) => item.orderUuids === printDetails)?.formattedAdditionalServicesValue
                     }
                     formattedClientValueWithServices={
                        data?.orders.find((item) => item.orderUuids === printDetails)?.formattedClientValueWithServices
                     }
                     formattedClientValue={
                        data?.orders.find((item) => item.orderUuids === printDetails)?.formattedClientValue
                     }
                     formattedValue={data?.orders.find((item) => item.orderUuids === printDetails)?.formattedValue}
                     title={getTitle()}
                     onClose={() => handleOpenPrint(undefined)}
                     details={savedOrderDetails}
                     updateDetailsStore={updateDetailsStore}
                     orderType={data?.orders.find((item) => item.orderUuids === printDetails)?.orderType}
                  />
               )}
            </>
         ) : (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
               <CircularProgress />
            </Box>
         )}
      </Box>
   )
}

export default OrdersHistory

const StyledTableWrapper = styled('div')({
   '.MuiTable-root': {
      overflowX: 'scroll',
   },
   '.MuiTable-head': {
      backgroundColor: 'rgba(33,147,224, 0.1)',
   },
   '.MuiTableCell-head': {
      backgroundColor: 'rgba(33,147,224, 0.1)',
      padding: '6px 4px 6px 4px',
      color: 'grayText',
      textAlign: 'center',
   },
   '.MuiTableCell-root': {
      textAlign: 'center',
      padding: '6px 4px 6px 4px',
   },
   '.MuiTableFooter-root': {
      backgroundColor: 'rgba(33,147,224, 0.1)',
   },
})
