/* eslint-disable import/no-named-default */
import { useLocation, useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import sendMessageService from 'shared/services/sendMessageService'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { useProfileQuery } from 'shared/store/useProfile'
import background from 'assets/svg/background.svg'

import errorimage from '../../../../assets/ilustrations/error.png'

export default function ErrorView() {
   const [isSending, setIsSending] = useState(false)
   const { addNotification } = useNotificationContext()
   const { t } = useTranslation('TRANSLATION')
   const navigate = useNavigate()
   const { data: useProfileData } = useProfileQuery()
   const location = useLocation()

   function onMessageSend() {
      setIsSending(true)
      const payload: ContactType = {
         message: `Wystąpił błąd z działaniem aplikacji ------ 
         errorFrom: ${location.state.variant} ------ 
         user: ${window.localStorage.getItem('user_uuid')} ------ 
         errorMessage: ${location.state.error.message ?? 'errorMessage unknown'} ------ 
         path: ${location.state.path ?? 'pathname unknown'} ------ 
         errorStack: ${location.state.error.stack ?? 'errorStack unknown'} ------ 
         date: ${new Date()} ------ 
         locationUuid: ${useProfileData?.locationUuid ?? 'unknown'}`,
         typeMessage: 'System functionality problems',
      }
      sendMessageService(payload)
         .then((res) => {
            if (res.status === 204) {
               addNotification('successSend', 'success')
               navigate('/')
            }
         })
         .catch((apiError) => {
            if (isAxiosError<ErrorType>(apiError)) {
               addNotification(apiError.response?.data?.errors[0].code || 'failedSend', 'error')
            } else {
               addNotification('failedSend', 'error')
            }
         })
         .finally(() => setIsSending(false))
   }

   return (
      <Box>
         <Box
            sx={{
               px: { xs: 2, md: 5 },
               py: 5,
               flexGrow: 1,
               backgroundImage: `url(${background})`,
               backgroundRepeat: 'no-repeat',
               backgroundSize: 'cover',
            }}
         >
            <Box
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '16px',
                  mt: '32px',
                  width: '100%',
               }}
            >
               <img src={errorimage} width={300} height={300} alt="error_image" />
               <Typography variant="h3" color="primary">
                  {t('errorPageTitle')}
               </Typography>
               <Typography variant="h5">{t('errorPageReport')}</Typography>
               <ContainedButton onClick={onMessageSend} disabled={isSending} variant="contained" sx={{ fontSize: 14 }}>
                  {t('backToHomeButton')}
               </ContainedButton>
            </Box>
         </Box>
      </Box>
   )
}
