import { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { GetOrdersResult } from './types'

function generateUrl(searchPhrase?: string, filter?: string, filterStatuses?: PropType<OrderType, 'status'>[]) {
   const searchParams = new URLSearchParams()

   if (searchPhrase) {
      searchParams.append('search', searchPhrase)
   }
   if (filter) {
      searchParams.append('orderDate', filter)
   }
   if (filterStatuses) {
      searchParams.append('status', filterStatuses.join(','))
   }

   return `orders/?${searchParams.toString()}`
}

export function getOrdersService(
   searchPhrase?: string,
   filter?: string,
   filterStatuses?: PropType<OrderType, 'status'>[]
) {
   const url = generateUrl(searchPhrase, filter, filterStatuses)
   let errMessage = ''

   return httpClient
      .get<GetOrdersResult>(url)
      .then((res) => {
         if (res.data) {
            return {
               data: res.data.orders.filter((item) => item.grossValueSum !== 0),
               startingYear: res.data.years.startingYear,
               selectedFilter: res.data.years.selectedFilter,
               availableFilters: res.data.years.availableFilters,
               currentYear: res.data.years.currentYear,
            }
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDERS HISTORY getOrders service', errMessage, 'error')
         throw error
      })
}

export function changeOrderNameService(orderName: string, orderUuid: string) {
   let errMessage = ''

   return httpClient
      .patch(`orders/${orderUuid}`, {
         cartName: orderName,
      })
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDERS HISTORY changeOrderName service', errMessage, 'error')
         throw error
      })
}
