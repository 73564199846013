import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { useNotificationContext } from 'shared/context/Notifications'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { AxiosError } from 'axios'
import { changeOrderNameService, getOrdersService } from './services'
import { SearchOrdersHistoryPayload, StateType } from './types'

async function getOrders(payload: SearchOrdersHistoryPayload) {
   const orders = await getOrdersService(payload.searchValue, payload.filter, payload.filterStatus)

   return {
      orders: orders.data,
      searchValue: payload.searchValue || '',
      currentYear: orders.currentYear,
      selectedFilter: payload.filter || '',
      startingYear: orders.startingYear,
      availableFilters: orders.availableFilters,
   }
}

export function useOrdersHistory(): UseQueryResult<StateType> {
   return useQuery(['ordersHistory'], () => getOrders({ searchValue: '', filter: 'LAST_90_DAYS' }))
}

export function useSearchOrdersHistory() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<StateType, AxiosError<ErrorType> | Error, SearchOrdersHistoryPayload, unknown>(
      (payload) => getOrders(payload),
      {
         onSuccess: (data) => {
            if (Array.isArray(data.orders)) {
               queryClient.setQueryData(['ordersHistory'], data)
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}

export function useChangeOrderNameMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType> | Error, { orderUuid: string; orderName: string }, unknown>(
      ({ orderUuid, orderName }) => changeOrderNameService(orderName, orderUuid),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('ordersHistory')
            }
         },
         onError: (error) => {
            if (error) {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || 'failedSave', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
